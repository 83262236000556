import { render, staticRenderFns } from "./project-from-stack.vue?vue&type=template&id=72d8f4fb&scoped=true&"
import script from "./project-from-stack.vue?vue&type=script&lang=js&"
export * from "./project-from-stack.vue?vue&type=script&lang=js&"
import style0 from "./project-from-stack.vue?vue&type=style&index=0&id=72d8f4fb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72d8f4fb",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLazy } from 'vuetify/lib/components/VLazy';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAvatar,VCard,VCol,VContainer,VDivider,VIcon,VLazy,VListItemAvatar,VListItemContent,VListItemSubtitle,VListItemTitle,VRow,VSelect,VSlideXReverseTransition,VTextField})


/* vuetify-loader */
import installDirectives from "!../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
