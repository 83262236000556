<template>
  <div class="project-from-stack">
    <CyDetails
      can-cancel
      hide-delete
      :loading="false"
      :on-cancel="() => { $router.push({ name: 'projects' }) }"
      :can-save="formIsValid && !_.isEmpty(selectedStack)"
      :on-save="createProject"
      :saving="creating"
      :save-btn-text="$t('createProject')">
      <template #details_formFullWidth>
        <div
          v-if="!selectedStack"
          class="stack-selection space-x-6">
          <div class="stack-selection__text">
            <h3
              class="mb-4"
              v-text="$t('selectStackTitle')"/>
            <p v-text="$t('selectStackText')"/>
          </div>
          <div class="stack-list__wrapper">
            <div class="stack-filters space-x-6">
              <v-text-field
                v-model="stackSearch"
                :label="$t('findAStack')"
                append-icon="search"
                class="stack-filters__search"/>
              <v-select
                v-model="queryParams"
                :items="$static.catalogStatusItems"
                :label="$t('stackType')"
                :menu-props="{
                  contentClass: 'v-menu--avatar-with-two-lines',
                  maxHeight: 'auto',
                }"
                class="stack-filters__type">
                <template #selection="{ item }">
                  <!-- Key on the avatar is required to prevent an issue when switching icons from different libraries -->
                  <v-avatar
                    :key="`avatar-${item.icon}`"
                    class="list_tile_avatar mx-1"
                    :tile="true"
                    size="24">
                    <v-icon>
                      {{ item.icon }}
                    </v-icon>
                  </v-avatar>

                  {{ item.name }}
                </template>

                <template #item="{ item }">
                  <v-list-item-avatar
                    class="list_tile_avatar my-0">
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content
                    data-cy="filter-option"
                    class="py-2">
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.hint }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-select>
              <v-select
                v-model="selectedProvider"
                :label="$t('provider')"
                :items="providerOptions"
                item-value="providerCanonical"
                class="stack-filters__provider">
                <template #selection="{ item }">
                  <CyIconCredential
                    v-if="item.providerCanonical"
                    size="24"
                    :type="item.providerCanonical"/>
                  {{ item.displayName }}
                </template>

                <template #item="{ item }">
                  <v-list-item-avatar
                    size="32"
                    class="list_tile_avatar my-0">
                    <CyIconCredential
                      v-if="item.providerCanonical"
                      :type="item.providerCanonical"/>
                  </v-list-item-avatar>

                  <v-list-item-content
                    class="py-2">
                    <v-list-item-title>{{ item.displayName }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
            </div>
            <ul
              v-if="fetchingStacks"
              class="stack-selection__loading">
              <li
                v-for="i in 6"
                :key="i"
                class="d-flex flex-column sk-template sk-block pa-4 mb-2">
                <div class="d-flex">
                  <div class="sk-block sk-img sk-dark sk-w-12 sk-h-12 mr-2"/>
                  <div class="d-flex flex-column">
                    <div class="sk-block sk-title sk-dark sk-w-40"/>
                    <div class="sk-block sk-title sk-dark sk-w-16 mt-2"/>
                  </div>
                </div>
              </li>
            </ul>
            <v-row
              v-else-if="_.isEmpty(filteredStacks)"
              class="stack-selection__empty">
              <v-col class="text-center mt-12">
                <v-icon
                  x-large
                  class="mb-4 mt-4">
                  do_not_disturb
                </v-icon>

                <h4 class="grey--text">
                  {{ $t('noServiceFound') }}
                </h4>
              </v-col>
            </v-row>
            <ul
              v-else
              aria-label="Stacks list"
              class="stack-selection__list">
              <!-- TODO: Remove :value workaround once it is no longer required for testing -->
              <v-lazy
                v-for="(stack, index) in filteredStacks"
                :key="stack.ref"
                min-height="111px"
                :aria-label="stack.name"
                class="mb-2"
                tag="li"
                :value="index < 6 ? true : undefined">
                <CyWizardServiceCard
                  :service="stack"
                  show-use-btn
                  show-details-btn
                  :details-btn-action="() => { previewedStack = stack }"
                  @selected="selectedStack = $event"/>
              </v-lazy>
            </ul>
          </div>
        </div>
        <v-container
          v-else
          fluid>
          <v-row
            align="start"
            no-gutters>
            <v-col>
              <h3 v-text="$t('Stack')"/>
            </v-col>
            <v-col
              cols="8"
              lg="5">
              <p
                class="subtitle grey--text text--darken-1 mb-2"
                v-text="$t('Stack')"/>
              <CyWizardServiceCard
                aria-label="Selected stack"
                :service="selectedStack"/>
              <button
                class="change-stack--btn mt-2"
                @click="selectedStack = null"
                v-text="$t('changeStack')"/>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.lgAndUp"/>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-divider class="my-8"/>
            </v-col>
            <v-col
              cols="8"
              lg="5">
              <v-divider class="my-8"/>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.lgAndUp"/>
          </v-row>
          <v-row
            align="start"
            no-gutters>
            <v-col>
              <h3 v-text="$t('general')"/>
            </v-col>
            <v-col
              cols="8"
              lg="5">
              <CyFormsProject
                @input="projectConfig = $event"
                @is-valid="formIsValid = $event"/>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.lgAndUp"/>
          </v-row>
        </v-container>
        <portal
          v-if="previewedStack"
          to="side-panel">
          <div class="side-panel-backdrop"/>
          <v-slide-x-reverse-transition>
            <v-card
              v-click-outside="{
                handler: closePreview,
                include: getClickOutsideExceptions,
              }"
              aria-label="Stack preview"
              role="region"
              class="side-panel">
              <CyStackPreview
                :stack="previewedStack"
                @close="closePreview"
                @select="selectFromPanel"/>
            </v-card>
          </v-slide-x-reverse-transition>
        </portal>
      </template>
    </CyDetails>
  </div>
</template>

<script>
import { constructBreadcrumb } from '@/utils/helpers'
import { mapState, mapActions, mapGetters } from 'vuex'
import CyWizardServiceCard from '@/components/wizard/service-card.vue'
import CyFormsProject from '@/components/forms/project.vue'
import CyDetails from '@/components/details.vue'
import CyStackPreview from '@/components/stack-preview.vue'

export default {
  name: 'CyPageProjectFromStack',
  components: {
    CyWizardServiceCard,
    CyFormsProject,
    CyDetails,
    CyStackPreview,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('title'), [
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },
    ])
  },
  header () {
    return {
      title: this.$t('title'),
      description: {
        text: this.$t('gettingStartedText'),
        link: $docLinks.project.deploy,
      },
    }
  },
  data: () => ({
    selectedStack: null,
    projectConfig: null,
    formIsValid: false,
    creating: false,
    stackSearch: '',
    queryParams: {},
    providerOptions: [],
    selectedProvider: null,
    previewedStack: null,
  }),
  computed: {
    ...mapState('organization', {
      stacks: (state) => state.available.stacks,
      fetchingStacks: (state) => state.fetchInProgress.stacks,
    }),
    ...mapGetters('organization/cloudCostManagement', [
      'getProviderExtraInfo',
    ]),
    $static () {
      return {
        catalogStatusItems: [
          {
            name: this.$t('routes.stacks'),
            hint: this.$t('stacks.catalogStatus.allHint'),
            value: {},
            icon: 'public',
          },
          {
            name: this.$t('stacks.catalogStatus.publicName'),
            hint: this.$t('stacks.catalogStatus.publicHint'),
            value: { catalogStatus: 'public' },
            icon: 'remove_red_eye',
          },
          {
            name: this.$t('stacks.catalogStatus.privateName'),
            hint: this.$t('stacks.catalogStatus.privateHint'),
            value: { catalogStatus: 'private' },
            icon: 'folder_special',
          },
          {
            name: this.$t('stacks.catalogStatus.ownName'),
            hint: this.$t('stacks.catalogStatus.ownHint'),
            value: { catalogOwn: true },
            icon: 'fa-sitemap',
          },
          {
            name: this.$t('stacks.catalogStatus.trustedName'),
            hint: this.$t('stacks.catalogStatus.trustedHint'),
            value: { trusted: true },
            icon: 'verified_user',
          },
          {
            name: this.$t('stacks.catalogStatus.infraImportName'),
            hint: this.$t('stacks.catalogStatus.infraImportHint'),
            value: { importStatus: true },
            icon: 'mdi-cloud-download',
          },
        ],
      }
    },
    filteredStacks () {
      const isMatch = (value) => value.toLowerCase().includes(this.stackSearch.toLowerCase())

      return _(this.stacks)
        .sortBy([
          ({ author }) => author !== 'Cycloid',
          'name',
        ]).filter((stack) => _(['name', 'canonical', 'description', 'author', 'keywords'])
          .flatMap((key) => stack[key])
          .some((field) => isMatch(field)),
        // TODO: FE#6435 switch to server side filtering once the import_status is supported
        ).filter((stack) => this.queryParams.importStatus ? !!stack?.import_status : true)
        .filter((stack) => {
          if (!this.selectedProvider) return true
          const providerKeywords = _(stack.keywords)
            .filter((keyword) => keyword.startsWith('provider:'))
            .map((keyword) => keyword.replace('provider:', ''))
            .value()
          return providerKeywords.some((provider) => provider === this.selectedProvider)
        })
        .value()
    },
  },
  watch: {
    queryParams: {
      handler () {
        this.getStacks()
      },
      deep: true,
    },
  },
  async created () {
    await this.getStacks()
    const { selectedStackRef } = this.$route.query
    if (selectedStackRef) {
      this.selectedStack = this.stacks.find((stack) => stack.ref === selectedStackRef)
      this.$router.replace({ query: {} })
    }
    this.setProviderOptions()
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapActions('organization/project', [
      'CREATE_PROJECT',
    ]),
    async getStacks () {
      const { catalogStatus = null, catalogOwn = null, trusted = null } = this.queryParams
      await this.FETCH_AVAILABLE({ keyPath: 'stacks', extraParams: [catalogStatus, catalogOwn, trusted] })
    },
    async createProject () {
      const { formIsValid, selectedStack, projectConfig } = this

      if (!formIsValid) return

      this.creating = true
      const noErrorsOccured = await this.CREATE_PROJECT(_.$snakeCaseKeys({ ...projectConfig, serviceCatalogRef: selectedStack.ref }))
      if (noErrorsOccured) this.$router.push({ name: 'projectEmpty', params: { projectCanonical: projectConfig.canonical } })
      this.creating = false
    },
    setProviderOptions () {
      const providers = _(this.stacks)
        .flatMap('keywords')
        .filter((keyword) => keyword.startsWith('provider:'))
        .map((keyword) => keyword.replace('provider:', ''))
        .uniq()
        .map((provider) => this.getProviderExtraInfo[provider])
        .value()
      const anyOption = {
        displayName: this.$t('any'),
        providerCanonical: null,
      }
      this.providerOptions = [anyOption, ...providers]
    },
    getClickOutsideExceptions () {
      const selectors = [
        '.main-nav a',
        '.main-nav button',
        '.dev-locale-switcher__options',
        '.dev-layer',
        '.v-menu__content',
      ]
      return Array.from(document.querySelectorAll(selectors.join(', ')))
    },
    closePreview () {
      this.previewedStack = null
    },
    selectFromPanel () {
      this.selectedStack = this.previewedStack
      this.closePreview()
    },
  },
  i18n: {
    messages: {
      en: {
        title: 'Create new @:project',
        any: 'Any',
        changeStack: 'Change stack',
        createProject: 'Create project',
        findAStack: 'Find a stack',
        gettingStartedText: 'A project is a set of environments built around a stack. Follow the Getting started guide to get familiar with projects.',
        noServiceFound: 'No stack found',
        selectStackText: 'Select the stack to use in this project, all environments in this project will reference this stack. The stack cannot be changed once the project is created.',
        selectStackTitle: 'Select stack',
        stackType: 'Stack type',
      },
      es: {
        title: 'Crear nuevo @:project',
        any: 'Cualquiera',
        changeStack: 'Cambiar stack',
        createProject: 'Crear un proyecto',
        findAStack: 'Buscar un stack',
        gettingStartedText: 'Un proyecto es un conjunto de entornos construidos alrededor de un stack. Sigue la guía de inicio para familiarizarte con los proyectos.',
        noServiceFound: 'No se encontraron stacks',
        selectStackText: 'Selecciona el stack a usar en este proyecto, todos los entornos de este proyecto harán referencia a este stack. El stack no se puede cambiar una vez creado el proyecto.',
        selectStackTitle: 'Seleccionar un stack',
        stackType: 'Tipo de stack',
      },
      fr: {
        title: 'Créer un nouveau @:project',
        any: 'Tous',
        changeStack: 'Changer de stack',
        createProject: 'Créer un projet',
        findAStack: 'Trouver une stack',
        gettingStartedText: `Un projet est un ensemble d'environnements construits autour d'une stack. Suivez le guide de démarrage pour vous familiariser avec les projets.`,
        noServiceFound: 'Aucun stack trouvé',
        selectStackText: `Sélectionnez la stack à utiliser dans ce projet, tous les environnements de ce projet feront référence à cette stack. La stack ne peut pas être modifiée une fois le projet créé.`,
        selectStackTitle: 'Sélectionner une stack',
        stackType: 'Type de stack',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.project-from-stack {
  .stack-list__wrapper {
    flex-grow: 1;
    padding: 0;
    padding-right: 8px;

    .stack-filters {
      display: flex;

      &__type,
      &__provider {
        max-width: 200px;
        width: 200px;
      }
    }
  }

  .stack-selection {
    display: flex;
    max-width: 100%;
    padding-right: 8px;

    &__text {
      flex-basis: 350px;
      flex-shrink: 0;
    }

    &__list {
      flex-grow: 1;
      padding: 0;
      list-style-type: none;
    }

    &__loading {
      display: flex;
      flex-direction: column;
      width: 100%;

      .sk-template {
        width: 100%;
      }
    }
  }

  .change-stack--btn {
    color: get-color("secondary");
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
  }
}

.v-list-item__avatar {
  .svg-inline--fa {
    width: 24px;
    height: 24px;
  }
}

.side-panel {
  $offset: 8px;

  display: flex;
  position: fixed;
  z-index: 110;
  top: $offset;
  right: $offset;
  flex-direction: column;
  width: 830px;
  height: calc(100% - #{$offset} * 2);

  &-backdrop {
    position: fixed;
    z-index: 108;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0 0 0 / 50%);
  }
}
</style>
